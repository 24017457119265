import { browserTracingIntegration, captureConsoleIntegration, init, replayIntegration } from '@sentry/astro'

init({
  dsn: 'https://df73c90d4692118e144cce172118f75f@o253203.ingest.us.sentry.io/4508638862049280',
  environment: process.env.BUILD_MODE,
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
    captureConsoleIntegration({
      levels: ['warn', 'error'],
    }),
  ],

  // Define how likely traces are sampled. Adjust this value in production,
  // or use tracesSampler for greater control.
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
})
